"use strict";
var $ = require("jquery");

module.exports = function () {
    var sizes = ["xs", "sm", "md", "lg", "xl"];

    sizes.forEach(function (size) {
        var selector = ".collapsible-" + size + " .title";
        var collapsiblePDP = ".collapsible-" + size;
        $("body").on("click", selector, function (e) {
            e.preventDefault();
            $(this).closest(".c-collapse").find(collapsiblePDP).removeClass("active");
            $(this).parents(".collapsible-" + size).toggleClass("active");

            if ($(this).parents(".collapsible-" + size).hasClass("active")) {
                $(this).attr("aria-expanded", true);
            } else {
                $(this).attr("aria-expanded", false);
            }
        });
    });
};
