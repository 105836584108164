"use strict";

window.addEventListener("load", function () {
    const buttonSelectors = [
        ".btn.banner-hover-contentWithImagebutton2, .btn.banner-hover-contentWithImagebutton1",
        ".btn.banner-hover-up-2-cta-button1",
        ".btn.banner-hover-buttonLink1, .btn.banner-hover-buttonLink2",
        ".btn.banner-hover-articlecontentbutton1, .btn.banner-hover-articlecontentbutton2",
        ".banner-btn.banner-hover-state-button1, .banner-btn.banner-hover-state-button2",
        ".btn.image-button-content-btn",
        ".button-link-button.banner-hover-link-button1"
    ];

    buttonSelectors.forEach(selector => {
        const buttons = document.querySelectorAll(selector);
        buttons.forEach(button => {
            const defaultStyles = {
                backgroundColor: button.style.backgroundColor,
                color: button.style.color,
                borderColor: button.style.borderColor
            };

            button.addEventListener("mouseover", function () {
                const hoverColor = button.getAttribute("data-hover-color");
                const hoverTextColor = button.getAttribute("data-hover-text-color");
                const hoverBorderColor = button.getAttribute("data-hover-border-color");

                if (hoverColor) {
                    button.style.backgroundColor = hoverColor;
                }
                if (hoverTextColor) {
                    button.style.color = hoverTextColor;
                }
                if (hoverBorderColor) {
                    button.style.borderColor = hoverBorderColor;
                }
            });

            button.addEventListener("mouseout", function () {
                button.style.backgroundColor = defaultStyles.backgroundColor;
                button.style.color = defaultStyles.color;
                button.style.borderColor = defaultStyles.borderColor;
            });
        });
    });
});
